import React from 'react'
import PropTypes from 'prop-types'

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'

const CategoryTeaser = ({ categories, isMobile }) => {
  return (
    <div className="dhsv_category_teaser">
      {isMobile ? (
        <Accordion allowZeroExpanded>
          {categories.map((category, index) => (
            <AccordionItem key={index}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <h3 className={`note__title`}>{category.title}</h3>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>{category.excerpt}</p>
                <a href={category.link} className="btn-default">
                  Weiterlesen
                </a>
              </AccordionItemPanel>
            </AccordionItem>
          ))}
        </Accordion>
      ) : (
        <div className="row">
          {categories && categories.length
            ? categories.map((category, key) => (
                <div key={key} className="col-12 col-md-6 col-lg-3">
                  <a className="category-teaser" href={category.link}>
                    <span
                      className="category-teaser__thumbnail"
                      dangerouslySetInnerHTML={{ __html: category.img }}
                    />
                    <span className="category-teaser__content-wrap">
                      <span className="category-teaser__title">
                        {category.title}
                      </span>
                      <span className="category-teaser__content">
                        {category.excerpt}
                      </span>
                    </span>
                  </a>
                </div>
              ))
            : ''}
        </div>
      )}
    </div>
  )
}

CategoryTeaser.propTypes = {
  isMobile: PropTypes.bool,
  categories: PropTypes.array,
}

export default CategoryTeaser
